<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Privacy Policy</h2>
		<p>
			Introduction<br />
			We deeply understand the importance of personal information to you and will strictly comply with legal and
			regulatory requirements to take corresponding security measures, committed to ensuring the security and
			controllability of your personal information. When you use PIX TECHNOLOGY PTE
			Before providing UniLive platform services under LTD company, please carefully read and fully understand this
			Basic Function Privacy Policy (hereinafter referred to as "this Privacy Policy") so that you can make appropriate
			usage choices
		</p>
		<p>
			This privacy policy only applies to the services of UniLive software, including websites, web pages, clients, and
			mini programs, as well as new service carriers that emerge with technological development.
		</p>
		<p>
			If you have any questions about this privacy policy, you can contact us through the methods specified in the "How
			to Contact Us" section of this privacy policy, and we will be happy to answer them for you. We will also revise
			this privacy policy from time to time based on laws, regulations, and changes in business development, and inform
			you in an appropriate manner.
		</p>
		<p>
			If you are a minor over 14 years old but under 18 years old, or do not have full capacity for civil conduct due to
			intellectual or mental health conditions, please read and agree to this privacy policy together under the guidance
			and accompaniment of your legal guardian; If you are a minor under the age of 14, please have your legal guardian
			read and agree to this privacy policy for you. Please and your legal guardian pay special attention to the
			protection of minors.
		</p>
		<h3>This privacy policy will help you understand the following content</h3>
		<p>1、 Collection and Use of Personal Information</p>
		<p>2、 Sharing, Transfer, and Disclosure of Personal Information</p>
		<p>3、 Protection and Storage of Personal Information</p>
		<p>4、 Your rights</p>
		<p>5、 Protection of Minors</p>
		<p>6、 Privacy Policy Access and Update</p>
		<p>7、 How to contact us</p>
		<h3>1、 Collection and Use of Personal Information</h3>
		<p>
			UniLive provides short video services for searching and playing videos of no more than a certain duration. If you
			do not agree with this privacy policy, you can click "Disagree" to enter visitor mode.
		</p>
		<p>
			We also provide you with more diverse products and/or services. In order to implement the functions and/or
			services you choose to use and comply with legal and regulatory requirements, we need to collect and use your
			corresponding personal information and/or grant necessary permissions. If you refuse, it may result in you being
			unable to use the function and/or service, but it does not affect the normal use of other unrelated functions
			and/or services. You can manage your personal information and permissions through Section 4 "Your Rights" of this
			Privacy Policy.
		</p>
		<p>
			If you agree to this basic privacy policy, it only means that you agree to the processing of necessary personal
			information when using UniLive basic functions. If you use other extension features of UniLive, we will seek your
			consent separately when using specific extension features.
		</p>
		<p>
			In specific scenarios, we will also inform you of the purpose, scope, and usage of the corresponding information
			collection through instant notifications (pop ups, page prompts) and feature update instructions. This type of
			explanation constitutes a part of this privacy policy and has the same legal effect as this privacy policy.
		</p>
		<p>
			Normally, in order to implement the following UniLive platform functions and/or services, your personal
			information will be collected and used or relevant permissions will be applied for:
		</p>
		<p>
			1. Account service
		</p>
		<p>
			To use our rich features and/or services (commenting, liking, bookmarking, posting videos), you need to provide
			relevant information/authorize to register and log in to the UniLive platform.
		</p>
		<p>
			1.1 Email: If you use email to log in to UniLive products and services, you need to provide your email address and
			password as necessary information.
		</p>
		<p>
			2. Content browsing, playback, and search
		</p>
		<p>
			2.1 Content browsing and playback
			When you browse and play content on the UniLive platform, we will record your device information (OAID, Android
			ID, IDFA) and personal internet browsing history (browsing, clicking, sharing, bookmarking) to provide you with a
			continuous and consistent user experience and ensure service quality.
		</p>
		<p>
			2.1.1 Location related browsing: When you use local services, we will apply for authorization to enable location
			permissions to collect and use your accurate geographic location information. If you do not authorize precise
			location information, it may affect the accuracy of positioning. We will rely on rough location information (WLAN
			access point (SSID, BSSID), Bluetooth and base station, sensor information, IP address information, which are
			collectively referred to as location information with precise geographic location information) and the location
			information provided in your use of related functions and/or services (the location you independently fill in your
			account information, the location link you add in your uploaded content) to determine your approximate location.
			This information will be used to display you with content and/or value-added services related to the above
			location information when you use the homepage, selection, discovery, and follow functions.
		</p>
		<p>
			2.1.2 Landscape playback: When you use landscape mode, we need to call your acceleration, gravity, and direction
			sensors to detect changes in the device screen direction through sensor information and switch between landscape
			and portrait.
		</p>
		<p>
			2.2 Search
		</p>
		<p>
			When you use the search function, we collect your device information (OAID AndroidID、IDFA）、 Search instructions
			(text, voice, or image) and log information. In order to provide efficient search services, keyword information
			will be temporarily stored on your local device and displayed accordingly when you click on the search icon. You
			can also choose to delete all or part of the search history.
			When you scan to identify QR codes, search for products, or conduct image searches, we will apply for
			authorization to enable camera and album permissions for you to take photos or upload images through the album for
			search purposes. When using a scan, we will call your light sensor to adjust screen brightness, and call your
			linear acceleration sensor to detect device direction and angle information, helping you smoothly identify the
			scanned content. If you do not agree to enable the relevant permissions, it will not affect your use of our basic
			functions.
		</p>
		<p>3. Social interaction</p>
		<p>
			We provide you with rich social interaction functions. To help you quickly meet more friends and engage in
			interactive communication, we will collect and use relevant information according to the following instructions.
		</p>
		<p>
			3.1 Content Interaction
		</p>
		<p>
			When you engage in interactive communication, we will collect and publicly display your interaction records and
			content (browsing, liking, commenting, following, bookmarking, forwarding, sharing, and @ other users).
		</p>
		<p>
			In the event that you share or receive shared content or activity information, UniLive needs to access your
			clipboard, read the passwords, sharing codes, and links contained in it, in order to achieve redirection, sharing,
			receiving benefits, and activity linkage services, help users open relevant content, and enhance user experience.
			We only recognize clipboard content locally and only upload it to the server when it belongs to UniLive commands.
			Additionally, when you share or save videos or images, we need to access your photo album.
		</p>
		<p>
			Some devices may prompt that the application is reading your clipboard or photo album, but these systems do not
			distinguish between local processing or uploading, and there is a possibility of false positives due to
			differences in statistical standards among different devices. If you have any related questions, please contact
			us.
		</p>
		<p>
			3.2 Message Notification
		</p>
		<p>
			You acknowledge and agree that UniLive may provide you with relevant content, activities, services, and feature
			information that you may be interested in and/or necessary through SMS, phone calls, email, and system
			notifications based on your authorized collection of mobile phone numbers and email addresses, to assist you in
			identity verification, security verification, and user experience research. If you do not wish to receive relevant
			push information, you can make relevant settings through the path provided in Section 4 "Your Rights" of this
			Privacy Policy,.
		</p>
		<p>4. Content shooting, production, and publishing</p>
		<p>
			4.1 Shooting and Production
		</p>
		<p>
			When you use short video and live streaming tools to shoot, produce audio, video, and image content, we will apply
			for authorization to enable camera permission for shooting and recording, album/storage permission (including SD
			card) for uploading album content and local synchronous storage to prevent work loss, and microphone permission
			for audio recording. Please be informed that we will only upload the audio, video, and image content you have
			selected for upload, and will not upload any other information. At the same time, in order to monitor lighting,
			adjust brightness, and achieve angle adjustment of the captured image, we will call on light sensors and
			acceleration sensors to your device. During this process, you can choose to provide us with full or partial
			permission authorization. If you do not agree to enable the relevant permissions, it will not affect your use of
			our basic functions.
		</p>
		<p>
			4.2 Release
		</p>
		<p>
			When you publicly post graphic and video content, we will collect relevant content and publicly display your
			avatar, nickname, and the above content information.
		</p>
		<p>
			4.2.1 Quick Upload: In order to improve the upload speed of the work to be published, enrich the publishing
			function, optimize the experience, and save data, before clicking the "publish" button after you finish shooting,
			we will start uploading the content on your device with WLAN connection and temporarily load and save it to our
			server. But please be aware that this feature will not release such works in advance, and will only submit them
			for publication when you click the "publish" button. If you cancel the publication or fail to upload for other
			reasons, we will immediately delete the relevant content. You can disable this feature through the path provided
			in Section 4 "Your Rights" of this Privacy Policy.
		</p>
		<p>5. Personalized recommendations</p>
		<p>
			5.1 Personalized recommendation service
			In order to showcase and recommend more relevant content to you and provide services that better meet your
			requirements, we will collect and use your browsing, playback, and search records during the use of UniLive
			platform services, combined with lawfully collected device information (OAID AndroidID、IDFA）、 Based on your
			preferred features, log information and other authorized information will be pushed to you, including pages,
			videos, live content, search results, friends you may know, commercial advertisements, services, and features that
			you may be interested in. If you authorize us to provide personalized recommendations based on your location
			information on the homepage, live streaming, or shopping mall page, we will also collect your location information
			in the corresponding functions for personalized recommendations.
		</p>
		<p>
			5.2 Personalized recommendation operation mechanism
		</p>
		<p>
			Personalized recommendations will be based on algorithmic models to predict your preference features, match
			content, services, or other information that you may be interested in, and rank the information presented to you.
			We will provide real-time feedback on the recommendation model based on your personal internet browsing history
			during your use of UniLive platform services, and continuously adjust and optimize the recommendation results. To
			meet your diverse needs, we will introduce diversified recommendation techniques in the sorting process to expand
			the recommended content and avoid excessive concentration of similar content.
		</p>
		<p>6. Customer Service</p>
		<p>
			To provide you with customer service, we may require you to provide necessary personal information for identity
			verification to ensure the security of your account. In order to facilitate contact with you, assist you in
			resolving issues as soon as possible, or record solutions and results for related issues, we may collect the
			following relevant information: contact information (the phone number/email address you used to contact us or
			other contact information you voluntarily provided to us), your communication information
			(text/pictures/audio/video/call records between you and us or relevant personnel), and other necessary information
			(account information, order information, other information you provide to prove relevant facts).
		</p>
		<p>
			In order to provide services and improve service quality, we may also use the following relevant information from
			you: the information you provide when contacting customer service, and the questionnaire response information you
			send us when participating in surveys.
		</p>
		<p>7. APP adaptation and operational security assurance</p>
		<p>
			7.1 In order to provide you with high-quality and reliable content and services, maintain the normal and stable
			operation of products and functions, protect your or other users' or the public's safety and legitimate interests
			from infringement through anti cheating and anti fraud measures, better prevent malicious programs, phishing
			websites, network vulnerabilities, computer viruses, network attacks, network intrusion security risks, and more
			accurately identify violations of laws and regulations or relevant rules of the UniLive platform, we need to
			collect, use, and integrate your device information, log information, sensor information, and other authorized
			information (collected from partners after obtaining your authorization in accordance with the law) to determine
			account security, transaction security, conduct identity verification, identify illegal and irregular situations,
			detect and prevent security incidents, and take necessary measures in accordance with the law. Record, analyze
			Disposal measures. We will only access your installed application information (application package name) or
			running process information, overall application operation, usage and frequency, application crash situation,
			overall installation and usage situation, performance data, and application source when user behavior triggers
			risk control conditions (multiple incorrect password inputs, frequent remote login, risky account, abnormal
			payment amount, abnormal withdrawal behavior), and only upload package name information of uncommon apps.
		</p>
		<p>
			Device information: Device identification information (AndroidID, IDFA, IDFV, UAID (China Mobile UAID, China
			Unicom UAID, and China Telecom UAID, limited to Android devices), OAID, Openudid, and other device identifiers
			formed by comprehensive device parameters and system information. Different identifiers may have different
			validity periods, whether they can be reset by users, acquisition methods, and different systems), network related
			information (Bluetooth, IP address, network operator, network status, type, access method, network quality data),
			device parameter information (device name, device model), software and hardware operating system information
			(version of this application, operating system, language, resolution).
		</p>
		<p>
			Log information: Personal online records of operations and usage (browsing, playing, searching, liking, following,
			bookmarking, commenting, downloading, sharing, trading).
		</p>
		<p>
			Please understand that when you switch UniLive to run in the background of your device, due to some device models
			and system reasons, the information collection behavior generated by your previous use of UniLive related
			functions may not be immediately stopped, resulting in temporary background information collection behavior.
		</p>
		<p>
			When conducting research and statistical analysis to improve operational quality and efficiency, we will de
			identify the personal information mentioned above and will not associate it with your true identity. The above de
			identified information will be used to improve the content and layout of the UniLive platform, provide product or
			service support for business decisions, and enhance the products and services we offer on the platform.
		</p>
		<p>8. Indirectly collecting personal information from third parties</p>
		<p>
			We will not proactively obtain your personal information from third parties. If it is necessary to obtain your
			personal information from a third party in order to implement relevant functions and services, we will confirm
			that the third party has obtained your authorization for the relevant personal information, or that the third
			party is legally permitted or required to disclose your personal information to us. If the personal information
			processing activities required for our services exceed the scope of your authorization when providing personal
			information to third parties, we will obtain your explicit consent.
		</p>
		<p>9. Cookies and similar technologies</p>
		<p>
			Cookies and similar device information identification technology are widely used in the Internet. When you use our
			services, we may use relevant technologies to send one or more cookies or anonymous identifiers (hereinafter
			referred to as "cookies") to your device to collect and identify information about your use of UniLive platform
			services.
			The use of cookies is mainly to ensure the security and efficient operation of product functions and services, to
			confirm the security status of your account and transactions, and to investigate related abnormal situations such
			as crashes and delays; Help you avoid the steps and processes of repeatedly filling out forms and entering search
			content, for a more relaxed user experience; At the same time, we may also use the above technologies to
			recommend, display, and push content that interests you, optimizing your selection and interaction of content. We
			promise not to use cookies for any purposes other than those described in this privacy policy.
			You can manage or delete cookies according to your preferences. Most browsers provide users with the function to
			clear browser cache data. You can operate to clear cookie data in the browser settings. In addition, you can also
			clear all cookies saved in the software. But after clearing, we may not be able to use the functions or services
			provided by us that rely on cookies.
		</p>
		<p>10. Third party SDK and API technology</p>
		<p>
			Some features and/or services require the use of third-party SDK (or API) technology. Please be aware that these
			third parties
			SDK (or API) may collect or use some of your personal information. We will conduct strict security checks on
			relevant SDKs (or APIs) and take technical measures to ensure that they process personal information in accordance
			with this privacy policy and any other relevant confidentiality and security measures. In addition, we will also
			sign agreements with companies, organizations, and individuals with whom we share user information to regulate the
			use of user information.
		</p>
		<p>11. Exceptions to obtaining consent</p>
		<p>
			According to laws and regulations, your consent is not required for processing your personal information in the
			following situations:<br />
			(1) Necessary for the conclusion and performance of a contract to which you are a party, or for the implementation
			of human resource management in accordance with legally established labor regulations and collective contracts
			signed in accordance with the law  <br />
		
			(2) Necessary for fulfilling statutory duties or obligations <br />
			
			(3) Necessary to respond to sudden public health emergencies or to protect the life, health, and property safety
			of natural persons in emergency situations <br />
			
			(4) Implementing news reporting and public opinion supervision for the public interest, and processing your
			personal information within a reasonable range <br />
			
			(5) Other circumstances stipulated by laws and administrative regulations.
		</p>
		<h3>2、 Sharing, Transfer, and Disclosure of Personal Information</h3>
		<p>
			We will not share your personal information with third parties unless, for the purposes stated in this privacy
			policy, we need to share your personal information with affiliated companies or third-party partners (service
			providers, network operators, device manufacturers, and other partners) in the following scenarios. We will adhere
			to the principles of legality, legitimacy, and necessity, carefully evaluate the processing purposes and security
			capabilities of our partners, and sign relevant agreements in accordance with legal provisions. At the same time,
			we will strictly supervise and manage our partners. Once any violation or breach of contract is found, we will
			immediately cease cooperation and hold them legally responsible.
		</p>
		<p>The relevant cooperation scenarios are as follows:</p>
		<p>1. To achieve relevant functions and services</p>
		<p>1.1 Login and Social Services: In order to provide you with third-party registration, login, and sharing
			services, our login and social service partners (email) may use your device information (OAID AndroidID、IDFA）。</p>
		<p>1.2 Third party merchants on the platform: In order to assist you in purchasing goods/services from third-party
			merchants, making appointments for goods/services, displaying customized discount information from third-party
			merchants, or providing you with after-sales service, we will share your order and transaction related information
			and/or account identification information with third-party merchants on the platform</p>
		<p>1.3 Payment Services: In order to ensure transaction security and complete payment for goods/services,
			third-party payment institutions we cooperate with may need to process your order information and device
			information (OAID AndroidID、IDFA) 。</p>
		<p>1.4 Logistics Services: In order to accurately and safely deliver and distribute the goods (purchased or
			collected through activities) to you, third-party logistics companies will inevitably obtain your relevant
			delivery information during the shipping and distribution process  </p>
		<p>1.5 Message Notification: In order to adapt the message push function to the device you are using, device
			manufacturers (Huawei, Xiaomi, OPPO, VIVO) will use your device model, version, and related device information
			(OAID AndroidID、IDFA)。</p>
		<p>1.6 Customer Service: In order to promptly handle your complaints, suggestions, and other appeals, the customer
			service provider needs to use your account and related information to understand, process, and respond to your
			needs in a timely manner. If necessary, it may also be necessary to use your mobile phone number or other
			communication methods to contact you </p>
		<p>2. Advertisement</p>
		<p>
			Based on our business model, we will conduct advertising push and placement. In order to achieve advertising push
			and placement, and to help us evaluate, analyze, optimize, and measure the effectiveness of advertising placement,
			we may need to share some of your information and data with advertisers, service providers, suppliers, and
			third-party partners (collectively referred to as "advertising partners"). We will use techniques such as de
			identification, anonymization, or encryption algorithms to process the data.
		</p>
		<p>
			2.1 Advertising Push and Placement: When we push and place advertisements, advertising partners may need to use
			device information (OAID AndroidID、IDFA)、 Tag information, or device information (OAID AndroidID、IDFA)、 Tag
			information, combined with other legally collected data, may optimize and enhance the effective reach of
			advertising push and placement.
		</p>
		<p>
			2.2 Advertising statistical analysis: We may share your de identified or anonymized device information (OAID
			AndroidID、IDFA)、 Tag information, log information (ad clicks, views, displays), and ad conversion data are used to
			evaluate, analyze, monitor, and measure the effectiveness of ad push and delivery services, in order to provide
			more suitable ad content for you; Advertisers and/or their authorized service providers may require us to share
			some or all of the aforementioned information for operational analysis of user sources, optimization of
			advertising strategies, or improvement of their products and services.
		</p>
		<p>2.3 Personal Information Collection and Reminder in Advertising Scenarios: You may need to actively fill in and
			submit corresponding personal information (contact information, address, specific information type subject to page
			display) in the landing pages of relevant third-party advertising push and placement. These information may be
			collected and processed by advertisers or their commissioned advertising partners</p>
		<p>3. Implement security and statistical analysis</p>
		<p>
			3.1 Ensuring Security of Use: In order to ensure the security of your account and/or property and improve the
			security of our and our affiliated companies and partner services, we may share necessary device information (OAID
			AndroidID、IDFA)、 Account information and log information are used to comprehensively assess your account and
			transaction risks, avoid fraud, prevent security incidents, and take necessary recording, auditing, and disposal
			measures in accordance with the law.
		</p>
		<p>3.2 Product Analysis: In order to analyze the stability and adaptability of our products and/or services, service
			partners may need to use product service logs (log information of product crashes and crashes), device information
			(OAID AndroidID、IDFA)。</p>
		<p>(2) Transfer</p>
		<p>If we need to transfer personal information due to merger, division, dissolution, or bankruptcy, we will inform
			you of the recipient's name or contact information. We will require the recipient to continue fulfilling the
			obligations stipulated in this privacy policy. If the recipient changes the original processing purpose or method,
			they will seek your consent again</p>
		<p>(3) Publicly available</p>
		<p>We will not proactively disclose information that you have not disclosed on your own, unless we comply with
			national laws and regulations or obtain your consent</p>
		<p>(4) Exceptions for sharing, transferring, and disclosing personal information</p>
		<p>According to laws and regulations, sharing, transferring, or disclosing your personal information does not
			require your consent in the following situations:</p>
		<p>1. It is necessary for the conclusion and performance of a contract to which you are a party, or for the
			implementation of human resource management in accordance with labor rules and regulations formulated in
			accordance with the law and collective contracts signed in accordance with the law</p>
		<p>2. Necessary for fulfilling statutory duties or obligations</p>
		<p>3. Necessary for responding to sudden public health emergencies or protecting the life, health, and property
			safety of natural persons in emergency situations</p>
		<p>4. Implement news reporting and public opinion supervision for the public interest, and process personal
			information within a reasonable range</p>
		<p>5. In accordance with the provisions of the Personal Information Protection Law, process personal information
			that individuals disclose on their own or other legally disclosed personal information within a reasonable scope</p>

		<h3>3、 Protection and Storage of Personal Information</h3>

		<p>(1) Information security system assurance</p>

		<p>

			To ensure your information security, we strive to adopt various reasonable physical, electronic, and management
			security measures that comply with industry standards (SSL, information encryption storage, data center access
			control) to protect your information, so that your information will not be leaked, damaged, or lost. We have an
			industry-leading data security management system centered around data lifecycle, which enhances the security of
			the UniLive service system from multiple dimensions including organizational construction, system design,
			personnel management, product technology, and personal information protection impact assessment.

		</p>

		<p>(2) Information Security Management System</p>

		<p>We have established a dedicated security department, security management system, and data security process to
			ensure the security of your personal information. We adopt strict data usage and access regulations to ensure that
			only authorized personnel can access your personal information. We will regularly provide security education and
			training to personnel, and conduct security audits on data and technology in a timely manner</p>

		<p>

			At the same time, we have also established the UniLive Security Emergency Response Center, which is maintained by
			a professional security technology and operations team to facilitate timely and effective response and disposal of
			various security vulnerabilities and emergencies, and to work with relevant departments to trace and combat
			security incidents. Once a personal information security incident occurs, we will inform you of the basic
			situation and possible impact of the security incident, the disposal measures we have taken or will take,
			suggestions for you to independently prevent and reduce risks, and remedial measures for you in accordance with
			the requirements of laws and regulations. We will inform you of the relevant situation of the event through email,
			letter, phone, and push notifications. When it is difficult to inform individual information subjects one by one,
			we will adopt a reasonable and effective way to issue announcements. At the same time, we will also report the
			handling of security incidents such as data breaches in accordance with the requirements of relevant regulatory
			authorities.

		</p>

		<p>(3) Account security risk prevention</p>

		<p>

			When using certain services, it is inevitable for you to disclose your personal information (phone number, bank
			account information) to the counterparty or potential counterparty. Please protect your personal information
			properly and only provide it to others when necessary. Do not trust transactions through third-party products or
			services to avoid information theft or even telecom fraud.

		</p>

		<p>(4) Information storage</p>

		<p>1. Storage location</p>

		<p>UniLive will store personal information collected and generated during its operations in China in accordance with
			laws and regulations. If your personal information storage location is transferred from within China to overseas,
			we will strictly comply with legal provisions or obtain your separate consent</p>

		<p>2. Storage period</p>

		<p>Generally speaking, we only retain your personal information for the time necessary to achieve the purpose and
			within the retention period specified by laws and regulations. After exceeding the above storage period, we will
			delete or anonymize your personal information</p>

		<p>If the UniLive platform terminates its services or operations, we will notify you of the termination in advance
			through delivery or announcement, and delete your personal information or anonymize it within a reasonable period
			of time after the termination of services or operations</p>



		<h3>4、 Your rights</h3>

		<p>

			We attach great importance to your rights as a personal information subject and make every effort to protect your
			control over your personal information. After completing the registration and login of your UniLive account, you
			can exercise your relevant rights through the following methods (the exercise path of rights for each associated
			product may vary, subject to actual circumstances), and we will respond to your request in compliance with legal
			and regulatory requirements. At the same time, you can also contact us through the methods specified in "How to
			Contact Us" in this privacy policy to exercise your relevant rights.

		</p>

		<p>(1) Accessing, copying, and transferring personal information</p>

		<p>You can access, copy, and transfer personal information through UniLive:</p>

		<p>(2) Correction and supplementation of personal information</p>

		<p>When you find that the personal information we process about you is inaccurate or incomplete, after verifying
			your identity, you can submit your correction or supplementary application to us through feedback and error
			reporting</p>

		<p>In general, you can correct or supplement the information you submit at any time, but for security and identity
			verification (email appeal service) reasons, some initial registration and verification information provided
			during registration are not subject to change</p>

		<p>(3) Delete personal information</p>

		<p>You can delete some of your information through the path listed in "(2) Correction and Supplement of Personal
			Information" above, or apply to cancel your account and delete all of your information</p>

		<p>In the following situations, you can also request us to delete your personal information:</p>

		<p>1. The processing purpose has been achieved, cannot be achieved, or is no longer necessary to achieve the
			processing purpose</p>

		<p>2. Stop providing products or services, or the retention period has expired</p>

		<p>3. You withdraw your consent</p>

		<p>4. Violating laws and regulations or handling personal information in violation of agreements</p>

		<p>5. Other circumstances stipulated by laws and regulations</p>

		<p>When you delete information from our service, due to applicable laws and security technology restrictions, we may
			not immediately delete the corresponding information from the backup system. We will cease processing other than
			storage and take necessary security measures until the backup can be cleared or anonymized</p>

		<p>（4） Withdrawal of consent and modification of permissions</p>

		<p>

			Please understand that each function and/or service requires some basic personal information to be completed. When
			you withdraw your consent, we cannot continue to provide you with the function and/or service corresponding to the
			withdrawal of consent, nor will we process your corresponding personal information. However, your decision to
			withdraw consent does not affect the validity of the personal information processing activities that have been
			carried out based on personal consent before the withdrawal.

		</p>

		<p>（5） Cancel account</p>

		<p>

			You can apply to cancel your account. To ensure that the account cancellation is applied for by the corresponding
			user or the authorized entity of the user, UniLive will collect and verify the identity information of the user
			and the applicant, and make every effort to protect the rights and interests of the user. UniLive strictly
			complies with laws and regulations regarding personal information protection, and the above information is only
			used for this account cancellation.

		</p>

		<p>UniLive reminds you that your account cancellation is irreversible. Unless otherwise stipulated by laws and
			regulations, after you cancel your account, we will delete or anonymize your personal information. If it is
			technically difficult to delete personal information, we will stop processing other than storage and taking
			necessary security measures</p>

		<p>（6） Rights and Interests of the Deceased's Account</p>

		<p>

			If a UniLive user unfortunately passes away, the account that has not been cancelled can become a mourning account
			after the death of the user, and UniLive will continue to protect the security of the user's personal information
			in the mourning account. The close relatives of the deceased can contact us through the methods specified in the
			"How to Contact Us" section of this privacy policy. Subject to not violating the deceased's arrangements before
			death and not infringing on the legitimate interests of others and the public, they may access, copy, correct, or
			delete the deceased's personal information after completing identity verification procedures to exercise their
			legal and legitimate rights and interests as stipulated by laws and regulations.

		</p>




		<h3>5、 Protection of Minors</h3>

		<p>

			We protect the personal information of minors in accordance with relevant national laws and regulations. We will
			only collect, use, share, transfer, and disclose the personal information of minors when permitted by law, with
			the explicit consent of parents or other guardians, or when necessary to protect minors. If we discover that
			personal information of minors has been collected without obtaining verifiable consent from parents or other
			guardians in advance, we will delete the relevant information. At the same time, we have established strict rules
			for the collection and use of information on minors to protect the personal information security of children
			(under 14 years old) and teenagers (over 14 years old but under 18 years old).

			If you are the guardian of a minor and have any questions about the personal information of the minor under your
			guardianship, you can contact us through the methods specified in "How to Contact Us" of this privacy policy.

		</p>




		<h3>6、 Privacy Policy Access and Update</h3>

		<p>（1） Visit this Privacy Policy</p>

		<p>1. We will inform you of this privacy policy when you first register for a UniLive account and allow you to make
			your own decision on whether or not to agree</p>

		<p>（2） This Privacy Policy Update</p>

		<p>UniLive may revise this privacy policy from time to time. Without your explicit consent or compliance with
			relevant laws and regulations, we will not reduce the rights you are entitled to under this privacy policy</p>

		<p>2. After the UniLive Privacy Policy is updated, UniLive will display the latest version to you via WeChat or
			other appropriate means when you log in, so that you can stay informed of the latest content of this Privacy
			Policy in a timely manner</p>

		<p>3. For major changes, we will provide significant notifications (email, private message, system notification,
			pop-up prompt)</p>



		<h3>7、 How to contact us</h3>

		<p>We have established a dedicated personal information protection team and responsible person. If you have any
			questions, complaints, opinions or suggestions regarding personal information protection, you can contact us
			through any of the following methods:</p>

		<p>1. Send an email to: unilive.team@gmail.com </p>

		<p>UniLive will review the issues as soon as possible and respond promptly after verifying your identity. Normally,
			we will respond to your request within fifteen working days</p>

	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>